<template>
  <div>
    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-sm="12"
        vs-xs="12"
      >
        <TarjetaDetalleProyecto class="w-full p-2 proyectCard" />
      </vs-col>
      <vs-col vs-lg="8" vs-sm="12" vs-xs="12">
        <GalleryComponent class="p-1" />
      </vs-col>
    </vs-row>
    <br />
    <vs-row>
      <vs-col vs-sm="12" vs-xs="12">
        <ApartamentsComponent />
      </vs-col>
    </vs-row>
    <br />
  </div>
</template>

<script>
import BreadcrumbComponent from '../../../layouts/components/BreadcrumbComponent.vue';
import GalleryComponent from "../components/galleryComponent.vue";
import ApartamentsComponent from "../components/LevelSelectComponent.vue";
import TarjetaDetalleProyecto from "../components/TarjetaDetalleProyecto.vue";
export default {
  data() {
    return {
      proyectSeleccted: ""
    };
  },
  components: {
    GalleryComponent,
    ApartamentsComponent,
    TarjetaDetalleProyecto
  },
  async mounted() {
    this.$store.state.project.breadcrumbs = [{pageName: 'Listado de propiedades' , route:'/seleccion-de-nivel'}];
  }
};
</script>

<style lang="scss">
.ProyectCard {
  display: block;
  height: 100%;
  margin: 0 auto;
}
</style>
