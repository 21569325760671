<template>
  <div>
    <vs-row class="flex">
      <vs-button
        @click="linkToProperty(property)"
        color="primary"
        icon-pack="feather"
        icon="icon-eye"
        class="mb-4 m-1"
        >Ver Unidad</vs-button
      >
      <vs-button
        @click="linkToQuotes(property)"
        color="success"
        icon-pack="feather"
        icon="icon-file"
        class="mb-4 m-1"
        >Cotizar Unidad</vs-button
      >
      <vs-button
        @click="setCompareList(property)"
        :disabled="compareList.find((o) => o.id === property.id)"
        color="success"
        icon-pack="feather"
        icon="icon-copy"
        class="mb-4 m-1"
        >Comparar</vs-button
      >
    </vs-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    property: {
      default: null,
      required: true,
    },
  },
  methods: {
    dispatchProperty(propertyId) {
      this.$store.dispatch("property/setSelectedPropertyId", { propertyId });
    },
    linkToProperty(property) {
      this.dispatchProperty(property.id);
      this.$router.push({ name: "Apartamento", params: { Id: property.id } });
    },
    linkToQuotes(property) {
      this.dispatchProperty(property.id);
      this.$router.push({ name: "Generar Cotización" });
    },
    ...mapActions("property", ["setCompareList"]),
  },
  computed: {
    ...mapGetters("property", ["compareList"]),
  },
};
</script>

<style></style>
