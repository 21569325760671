<template>
<div class="p-1">
<vx-card class="cardListApartamentStep">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-lg="8"
            vs-sm="12"
            vs-xs="12"
          >
            <img
              class="inventoryStepImg"
              src="https://flattlo-app.s3.amazonaws.com/base-stock-images/Inventory_step_agrid.jpg"
              alt
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-lg="4"
            vs-sm="12"
            vs-xs="12"
          >
            <div>
              <h6 class="textStepInventory">
                No existen unidades
                <br />de inventario.
              </h6>
              <vs-button
                icon-pack="fas"
                to="/importador-de-apartamentos"
                icon="fa-file-excel"
                class="mt-6 btnInventoryStep"
              >Importar</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>