<template>
  <div>
    <div v-if="project.project_images.length === 0">
      <div>
        <vx-card class="blockGalleryStart">
          <h4 class="textStepGallery p-4">
            No existen imagenes<br />
            para {{ project.name }}.
          </h4>
          <vs-button
            icon-pack="feather"
            to="/galerias-de-proyecto"
            icon="icon-image"
            class="mt-6 ml-4 btnGalleryStep"
            >Agregar</vs-button
          >
          <div class="imgStepBlock">
            <img
              class="galleryStepImg"
              src="https://flattlo-app.s3.amazonaws.com/base-stock-images/gallery_step_incomplete.jpg"
            />
          </div>
        </vx-card>
      </div>
    </div>
    <div v-if="project.project_images.length > 0" class="mt-2">
      <swiper :options="swiperOption">
        <swiper-slide :key="img.id" v-for="img in finishesImages">
          <img class="slider-apartamento" :src="img.img_url" alt="banner" />
        </swiper-slide>

        <div
          class="swiper-pagination swiper-pagination-white"
          slot="pagination"
        ></div>
        <div
          class="swiper-button-prev swiper-button-white"
          slot="button-prev"
        ></div>
        <div
          class="swiper-button-next swiper-button-white"
          slot="button-next"
        ></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { api } from "../../projects/services";

export default {
  data() {
    return {
      project: null,
      swiperOption: {
        spaceBetween: 30,
        effect: "fade",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: {
    swiper,
    swiperSlide,
  },
  async created() {
    const projectId = this.$store.getters["project/currentProject"];
    this.project = await api.getImages(projectId);
  },
  computed: {
    finishesImages() {
      if (!this.project.project_images.length) return null;
      const finishes = this.project.project_images.filter((img) => {
        if (img.project_image_gallery === "FINISHES") {
          return img;
        }
      });
      return finishes;
    }
  },
};
</script>


<style lang="scss">
.slider-apartamento {
  display: block;
  height: 49vh;
  width: 100%;
  object-fit: cover;
}
.blockGalleryStart {
  height: 25.5em;
}
.galleryStepImg {
  height: 12em;
  margin-top: -11.666em;
}
.textStepGallery {
  font-size: 2em;
  z-index: -5;
  font-weight: 400;
  margin-top: 2em;
}
.btnGalleryStep {
  width: 186px;
  height: 3em;
  border-radius: 23em;
  -webkit-box-shadow: 0px 7px 26px -7px rgba(79, 117, 232, 1);
  -moz-box-shadow: 0px 7px 26px -7px rgba(79, 117, 232, 1);
  box-shadow: 0px 7px 26px -7px rgba(79, 117, 232, 1);
}
.btnGalleryStep:hover {
  background-color: #43a047 !important;
}

@media (min-width: 300px) and (max-width: 480px) {
  .galleryStepImg {
    visibility: hidden;
  }
  .imgStepBlock {
    display: none;
  }

  .textStepGallery {
    font-size: 1.8em;
    font-weight: 400;
    margin-top: 2em;
  }
}
@media only screen and (min-width: 768px) {
  .galleryStepImg {
    height: 16.5em;
    margin-left: 23.5555em;
    margin-top: -8em;
  }
}
@media only screen and (min-width: 1024px) {
  .galleryStepImg {
    height: 16.5em;
    margin-left: 18.5em;
    margin-top: -5em;
  }
  .blockGalleryStart {
    height: 28.5em;
  }
}
@media only screen and (min-width: 1044px) {
  //BtnEditDashboard
  .galleryStepImg {
    height: 18em;
    margin-left: 19em;
    margin-top: -9.666em;
  }
  .blockGalleryStart {
    height: 25.5em;
  }
}
@media only screen and (min-width: 1366px) {
  //BtnEditDashboard
  .galleryStepImg {
    height: 17em;
    margin-left: 20.5em;
    margin-top: -6.888em;
  }
  .blockGalleryStart {
    height: 27em;
  }
}
@media only screen and (min-width: 1536px) {
  //BtnEditDashboard
  .galleryStepImg {
    height: 19em;
    margin-left: 26.5555em;
    margin-top: -10.666em;
  }
  .blockGalleryStart {
    height: 25.5em;
  }
}
@media only screen and (min-width: 2560px) {
  //BtnEditDashboard
  .galleryStepImg {
    margin-left: 30.5em;
  }
}
</style>