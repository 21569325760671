<template>
  <div>
    <EmptyProjectsComponent v-if="!loading && emptyTable" />
    <div class="p-1" v-if="!loading && !emptyTable">
      <ActionsComponent v-if="propertySelected.length != 0" :property="propertySelected[0]" />
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"></div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        </div>
        <DataTable
          :headers="headers"
          :itemsData="properties"
          :itemPerPage="itemPerPage"
          :totalItems="totalPages"
          @serverSideMethod="loadProperties"
          :itemsSelected="propertySelected"
          :load="loadingTable"
          @changeSelect="onSelectionChanged"
          sortBy="number"
          :sortAsc=true
        >
        </DataTable>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DataTable from "../../../components/DataTable.vue";
import EmptyProjectsComponent from "./EmptyProjectsComponent";
import ActionsComponent from "./ActionsComponent";
import currencies from "../../../helpers/currencies";
import { quotesApi } from "../services";

export default {
  components: {
    DataTable,
    EmptyProjectsComponent,
    ActionsComponent
  },
  data() {
    return {
      searchQuery: "",
      itemPerPage: 10,
      emptyTable: false,
      totalPages: 20,
      properties: [],
      loading: true,
      loadingTable: true,
      propertySelected: [],
      headers:[]
    };
  },
  async beforeMount(){
    this.headers = [
        {
          text: "Unidad",
          value: "unit_number",
          width: 130,
          align: "left",
          filterValue: { type: null, value: "" },
          filter: true
        },
        {
          text: "Torre.",
          value: "TOWER_NUMBER",
          width: 120,
          align: "left",
          sortable: false,
          cellRenderer: "composed",
          filterType: "Attribute",
          filterValue: {type:"",value:""},
          filter: true,
          cellRendererParams: item => {
            return (
              this.propertyAttribute(
                item.property_attribute_values,
                "TOWER_NUMBER"
              ) || "-"
            );
          }
        },
        {
          text: "Tipo",
          value: "property_type.name",
          width: 120,
          cellRenderer:'composed',
          cellRendererParams: item => item.property_type ? item.property_type.name : "-",
          align: "left"
        },
        {
          text: "Modelo",
          value: "project_unit_type.type",
          width: 130,
          filterType: "Composed",
          filterValue: { type: "", value: "" },
          filter: true,
          cellRenderer:'composed',
          cellRendererParams: item => item.project_unit_type ? item.project_unit_type.type : "-",
          align: "left"
        },
        {
          text: "Nivel",
          value: "level",
          align: "left",
          filterType: "Number",
          filterValue: { type: "", value: "" },
          filter: true,
          width: 120
        },
        {
          text: "Hab,",
          value: "rooms",
          align: "left",
          filterType: "Number",
          filterValue: { type: "", value: "" },
          filter: true,
          width: 120
        },
        {
          text: "Precio,",
          value: "price",
          align: "left",
          filterType: "Number",
          filterValue: { type: "", value: "" },
          filter: true,
          cellRenderer:'composed',
          cellRendererParams: item => {
            return this.getFormatCurrency(
              item.price +
                this.getPropertiesAssignedSum(
                  item.assigned_properties,
                  "PARKING"
                ) +
                this.getPropertiesAssignedSum(
                  item.assigned_properties,
                  "WAREHOUSE"
                )
            );
          },
          width: 140
        },
        {
          text: "Área Const.",
          value: "construction_area",
          align: "left",
          filterType: "Number",
          filterValue: { type: "", value: "" },
          filter: true,
          cellRenderer:'composed',
          cellRendererParams: item => item.construction_area ? `${item.construction_area} m2` : "-",
          width: 160
        },
        {
          text: "Baños",
          value: "bathrooms",
          filterType: "Number",
          filterValue: { type: "", value: "" },
          filter: true,
          width: 120
        },
        {
          text: "Balcon",
          value: "BALCONY",
          cellRenderer:'composed',
          filterType: "Attribute",
          filterValue: {type:"",value:""},
          filter: true,
          cellRendererParams: item => {
            return (
              this.propertyAttribute(
                item.property_attribute_values,
                "BALCONY"
              )==="true" ? "SI" : "NO"
            );
          },
          width: 120
        },
        {
          text: "Parqueos",
          value: "assigned_parking",
          cellRenderer:'composed',
          cellRendererParams:(item) => this.getPropertiesAssignedQty(item.assigned_properties, "PARKING"),
          width: 120
        },
        {
          text: "Bodegas",
          value: "assigned_warehouse",
          cellRenderer:'composed',
          cellRendererParams:(item) => this.getPropertiesAssignedQty(item.assigned_properties, "WAREHOUSE"),
          width: 120
        },
        {
          text: "Vista.",
          value: "VIEW_STREET",
          width: 120,
          align: "left",
          sortable: false,
          cellRenderer: "composed",
          filterType: "Attribute",
          filterValue: {type:"",value:""},
          filter: true,
          cellRendererParams: item => {
            return (
              this.propertyAttribute(
                item.property_attribute_values,
                "VIEW_STREET"
              ) || "-"
            );
          }
        }
    ]
  },
  computed: {
    ...mapState("auth", ["user"])
  },
  methods: {
    onSelectionChanged(selected) {
      this.propertySelected = selected;
    },
    mapExtraField(properties, field) {
      return properties.map(prop => {
        let attr = prop.property_attribute_values.filter(
          p => p.property_attribute == field
        );
        if (attr.length) prop[field.toLowerCase()] = JSON.parse(attr[0].value);
        return prop;
      });
    },
    getFormatCurrency(value) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];

      return currencies.currencyFormatter({
        value: value,
        currency: currencySymbol
      });
    },
    getPropertiesAssignedSum(propertiesAssigned, propertyTypeId = "PARKING") {
      if (!propertiesAssigned.length) return 0;

      const props = this.getPropertiesAssigned(
        propertiesAssigned,
        propertyTypeId
      );

      if (!props.length) return 0;

      const sum = props
        .map(i => i.property.price)
        .reduce((previous, current) => previous + current);

      return sum;
    },
    getPropertiesAssignedQty(propertiesAssigned, propertyTypeId = "PARKING") {
      if (!propertiesAssigned.length) return 0;

      const props = this.getPropertiesAssigned(
        propertiesAssigned,
        propertyTypeId
      );

      if (!props.length) return 0;

      return props.length;
    },
    getPropertiesAssigned(properties, propertyTypeId = "PARKING") {
      return properties.filter(
        i => i.property.property_type_id === propertyTypeId
      );
    },
    propertyAttribute(attributeValues, value) {
      const attribute = attributeValues.find(
        propertyValue => propertyValue.property_attribute == value
      );

      if (!attribute) return null;

      return attribute.value;
    },
    async loadProperties(options) {
      const projectId = this.$store.getters["project/currentProject"];
      
      const { ordering, pagination, filtering } = options;
      this.loadingTable = true;
      const properties = await quotesApi.listAvailable(
        projectId,
        null,
        ordering,
        pagination,
        filtering
      );
      
      this.properties = this.mapExtraField(properties, "BALCONY");
      this.loadingTable = false;
      this.loading = false;
    }
  },
  async mounted() {
    const projectId = this.$store.getters["project/currentProject"];
    const totalPages = await quotesApi.totalPropertyListAvailable(
        projectId,
        "APARTMENT"
      );
    this.totalPages = totalPages;
    await this.loadProperties({ordering:"", pagination:", limit:10, offset: 0", filtering:""});
    this.emptyTable = this.totalPages ? false : true;
  }
};
</script>

<style lang="scss">
.cardListApartamentStep {
  height: 36em;
  margin-top: -1em;
}
.textStepInventory {
  font-size: 2.3em;
  z-index: -5;
  font-weight: 400;
  margin-top: 2em;
}
.inventoryStepImg {
  display: block;
  position: relative;
  height: 34em;
}
.btnInventoryStep {
  width: 186px;
  height: 3em;
  border-radius: 23em;
  -webkit-box-shadow: 0px 7px 26px -7px rgba(79, 117, 232, 1);
  -moz-box-shadow: 0px 7px 26px -7px rgba(79, 117, 232, 1);
  box-shadow: 0px 7px 26px -7px rgba(79, 117, 232, 1);
}
.btnInventoryStep:hover {
  background-color: #43a047 !important;
}

@media (min-width: 300px) and (max-width: 480px) {
  .inventoryStepImg {
    height: 9em;
    display: block;
    margin: 0 auto;
  }
  .textStepInventory {
    font-size: 2em;
    display: block;
    text-align: center;
  }
  .btnInventoryStep {
    margin: 0 auto;
    width: 90%;
  }
}

@media only screen and (min-width: 768px) {
  .inventoryStepImg {
    height: 18em;
    display: block;
    margin: 0 auto;
  }
  .btnInventoryStep {
    margin: 0 auto;
    width: 90%;
  }
  .textStepInventory {
    font-size: 2em;
    display: block;
    text-align: left;
  }
}
@media only screen and (min-width: 1024px) {
  .cardListApartamentStep {
    height: 24em;
  }
  .btnInventoryStep {
    margin: 0 auto;
    width: 70%;
    margin-left: -2.8888%;
  }
}

@media only screen and (min-width: 1366px) {
  .inventoryStepImg {
    height: 22em;
    margin-left: -1em;
  }
  .textStepInventory {
    margin-top: 3em;
  }
}
</style>
