<template>
  <div class="ProyectCard">
    <vx-card>
      <vs-row>
        <vs-col vs-justify="center" vs-align="center" vs-w="6">
          <h3>Proyecto</h3>
          <h6 class="mt-2">{{ project.name }}</h6>
        </vs-col>
      </vs-row>

      <br />
      <p>{{ project.description }}</p>
      <vs-list class="mt-2">
        <vs-list-item
          icon-pack="feather"
          icon="icon-box"
          title="Unidades totales:"
          :subtitle="`${project.properties_total.aggregate.count} unidades`"
        ></vs-list-item>
        <vs-list-item
          icon-pack="feather"
          icon="icon-box"
          title="Unidades disponibles:"
          :subtitle="`${project.properties_available.aggregate.count} unidades`"
        ></vs-list-item>
        <vs-list-item
          class="mt-1"
          icon-pack="feather"
          icon="icon-flag"
          title="Etapa actual:"
          :subtitle="getActualStatus(project.stage)"
        ></vs-list-item>
      </vs-list>
      <div class="w-full grid gap-2">
        <button
          @click="openPresentationPopUp()"
          class="flex p-2 px-5 md:mt-0 mt-1 rounded-lg bg-blue-100 text-blue-600 hover:bg-success-flattlo hover:text-white"
        >
          <!-- Heroicon name: outline/speakerphone -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
            />
          </svg>
          <p class="ml-2">Ver Presentación</p>
        </button>
        <button
          @click="openPopUp()"
          class="flex p-2 px-5 md:mt-0 mt-1 rounded-lg bg-blue-100 text-blue-600 hover:bg-success-flattlo hover:text-white"
        >
          <!-- Heroicon name: outline/speakerphone -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"
            />
          </svg>
          <p class="ml-2">Video de proyecto</p>
        </button>
        <button
          @click="openVideoPopup()"
          class="flex p-2 px-5 md:mt-0 mt-1 rounded-lg bg-blue-100 text-blue-600 hover:bg-success-flattlo hover:text-white"
        >
          <!-- Heroicon name: outline/speakerphone -->

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
            />
          </svg>
          <p class="ml-2">Tour Virtual</p>
        </button>
      </div>
    </vx-card>
    <MediaPopUpComponent
      :dialog="dialog"
      :title="popUpTitle"
      :url="popUpUrl"
      :iframe="iframeData"
      @closePopup="closePopUp"
    />
  </div>
</template>

<script>
import { api } from "../../projects/services";
import MediaPopUpComponent from "./MediaPopUpComponent";
export default {
  components: {
    MediaPopUpComponent
  },
  data() {
    return {
      project: null,
      dialog: false,
      popUpUrl: "",
      popUpTitle: "",
      iframeData: ""
    };
  },
  async mounted() {
    const projectId = this.$store.getters["project/currentProject"];
    this.project = await api.getSummary(projectId);
  },
  methods: {
    getActualStatus(stage) {
      switch (stage) {
        case "PLANS":
          stage = "Planos";
          break;
        case "LAUNCHING":
          stage = "Lanzamiento";
          break;
        case "BUILDING":
          stage = "En construcción";
          break;
        case "FINISHED":
          stage = "Finalizado";
          break;
        default:
          stage;
          break;
      }
      return stage;
    },
    openPopUp() {
      const url = this.getMediaUrl();
      this.popUpTitle = this.project.name;
      this.popUpUrl = url;
      this.dialog = true;
    },
    openPresentationPopUp() {
      const iframe = this.getPresentation();
      this.popUpTitle = this.project.name;
      this.popUpUrl = null;
      this.iframeData = iframe;
      this.dialog = true;
    },
    openVideoPopup() {
      const url = this.getVideoTour();
      this.popUpTitle = this.project.name;
      this.popUpUrl = url;
      this.dialog = true;
    },
    closePopUp() {
      this.dialog = false;
      this.popUpUrl = "";
    },
    getPresentation() {
      if (this.project.project_setting_values.length === 0) return null;
      let info = this.project.project_setting_values;
      let v = info.filter(v => v.project_setting == "EMBED_CODE_SLIDE");
      return v[0].value;
    },
    getVideoTour() {
      if (this.project.project_setting_values.length === 0) return null;
      let info = this.project.project_setting_values;
      let v = info.filter(v => v.project_setting == "VIDEO_TOUR_URL");
      return v[0].value;
    },
    getMediaUrl() {
      if (this.project.project_setting_values.length === 0) return null;
      let info = this.project.project_setting_values;
      let v = info.filter(v => v.project_setting == "VIDEO_URL");
      if (v[0].value.includes("https://youtu.be/")) {
        return v[0].value.replace(
          "https://youtu.be/",
          " https://www.youtube.com/embed/"
        );
      }
      if (v[0].value.includes("https://www.youtube.com/watch?v=")) {
        return v[0].value.replace(
          "https://www.youtube.com/watch?v=",
          " https://www.youtube.com/embed/"
        );
      } else {
        return v[0].value;
      }
    }
  }
};
</script>

<style lang="scss">
.ProyectCard {
  height: 100%;
}
</style>
