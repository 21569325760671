<template>
    <div class="centerx">
        <vs-popup @close="onClickCloseButton" fullscreen :title="title" :active.sync="dialog">
            <iframe
            v-if="url != null || undefined"
            width="100%"
            height="700"
            :src="url"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="rounded-3xl mt-10"
            ></iframe>
            <div v-else class="w-full h-full">
                <span v-html="iframe"></span>
            </div>
        </vs-popup>
    </div>
</template>

<script>
export default {
    data(){
        return{
            iframeValue:''
        }
    },
    props: ['dialog', 'title', 'url', 'iframe'],
    mounted() {
        this.getVideo();
        const next =this.$children[0].$refs.btnclose;
        next.$el.addEventListener('click', this.onClickCloseButton, false);
    },
    methods: {
        onClickCloseButton() {
            this.$emit('closePopup');
        },
    }
}
</script>

<style>

</style>
